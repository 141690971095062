import { useEffect, useRef } from 'react';

type Props = {
    html: string;
}

const HtmlParser: React.FC<Props> = ({ html }) => {
    const container = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (container.current) {
            container.current.innerHTML = html.replace(/\\"/g, '"')
        }
    }, [html])
    return (
        <div ref={container} style={{ overflow: "auto" }}>
        </div>
    );
};

export default HtmlParser;
